import React, {useState} from 'react'
import sessionStore from '../stores/sessionStore'
import LanguageSwitch from './LanguageSwitch'
import { useTranslation } from "react-i18next"
import Loading from './Loading'

import { Button, Box, TextField, IconButton, Tooltip, Divider, Paper, Typography, CardContent, Card } from '@mui/material'
import Infobox from './Infobox'
import { env } from '../api/api'
import { navigate } from "wouter/use-location"
import { Info, Key } from '@mui/icons-material'

const Login: React.FC = () => {
    const { t } = useTranslation()
    const { _login, _podId } = Object.fromEntries(new URLSearchParams(window.location.search))
    const podId = _podId ? _podId : ''

    const [loading, setLoading] = useState(false)
    const [showShrimpLogin, setShowShrimpLogin] = useState<true|false|undefined>(podId ? true : undefined)
    const [showInformation, setShowInformation] = useState(false)
    const [scope, setScope] = useState<string>(podId)
    const [login, setLogin] = useState<string>(_login ? _login : '')
    const [password, setPassword] = useState<string>('')

    const handleKeycloakLogin = (async() =>{
      setLoading(true)
      await sessionStore.login('keycloak')
      setLoading(false)
    })

    const handleMbrLogin = (async() =>{
      setLoading(true)
      await sessionStore.login('mbr')
      setLoading(false)
    })

    const handleSatosaLogin = (async() =>{
      setLoading(true)
      await sessionStore.login('satosa')
      setLoading(false)
    })

    const handleLocalLogin = async() => {
      setLoading(true)
      const r = await sessionStore.login('local', { login, password, podId:scope })
      setLoading(false)
      if (r) setShowShrimpLogin(false)
    }

    if(loading) return <Infobox>
      <Loading info="Waiting for login" />
    </Infobox>

    const buttons:JSX.Element[] = []

    if (env.idps.findIndex((idp:any)=>idp.name==='satosa') > -1) buttons.push(<Card key='satosa' elevation={3} style={{cursor:'pointer', marginBottom:10}} onClick={handleSatosaLogin} >
      <CardContent>
      <Typography sx={{ fontSize: 14 }} gutterBottom>{t('For Universities')}:</Typography>
      <Typography variant="body2" color="text.secondary">{t('Uni-Login-Explainer')}</Typography>
      <Button sx={{margin:'10px 0 10px 0px',width:'100%'}} style={{justifyContent:'left', color:'#564c44', borderColor:'#564c44'}} variant="outlined" disabled={loading} startIcon={<img src="/img/saml.png" />}>
        {t('Login via DFN / University')}
      </Button>
      </CardContent>
    </Card>)

    if (env.idps.findIndex((idp:any)=>idp.name==='mbr') > -1) buttons.push(<Card key='mbr' elevation={3} style={{cursor:'pointer', marginBottom:10}}>
    <CardContent>
      <Button sx={{margin:'10px 0 10px 0px',width:'100%'}} style={{justifyContent:'left', color:'#564c44', borderColor:'#564c44'}} variant="outlined" onClick={handleMbrLogin} disabled={loading} startIcon={<img src="/img/oidc.png" />}>
      {t('Login via Mein Bildungsraum')}
    </Button>
    </CardContent></Card>)

    if (env.idps.findIndex((idp:any)=>idp.name==='keycloak') > -1) buttons.push(<Card key='keycloak' elevation={3} style={{cursor:'pointer', marginBottom:10}}>
      <CardContent>
      <Button sx={{margin:'10px 0 10px 0px',width:'100%'}} style={{justifyContent:'left', color:'#564c44', borderColor:'#564c44'}} variant="outlined" onClick={handleKeycloakLogin} disabled={loading} startIcon={<span style={{width:24, height:24, backgroundColor:'#777', border:'1px solid #00c'}} />}>
      {t('Login via Keycloak')}
    </Button>
    </CardContent></Card>)

    if (env.idps.findIndex((idp:any)=>idp.name==='local') > -1) buttons.push(<Card key='local' elevation={3} style={{cursor:'pointer', marginBottom:10}} onClick={() => setShowShrimpLogin(!showShrimpLogin)}>
    <CardContent>
    <Typography sx={{ fontSize: 14 }} gutterBottom>{t('For Schools and external users')}:</Typography>
    <Typography variant="body2" color="text.secondary">{t('School-Login-Explainer')}</Typography>
      <Button sx={{margin:'10px 0 10px 0px',width:'100%'}} style={{justifyContent:'left', color:'#564c44', borderColor:'#564c44'}} variant="outlined" onClick={() => setShowShrimpLogin(!showShrimpLogin)} disabled={loading} startIcon={<Key fontSize="inherit" />}>
        {t('Login via SHRIMP Login')}
      </Button>
      </CardContent>
    </Card>)


    const localForm = <Box style={{backgroundColor:'white'}}>
      <Box style={{fontSize:'13px', lineHeight:1.2, marginBottom:'1.5em'}}>
        {t('Login via username and password is only available if you have received login credentials from your teacher or from the SHRIMP team.')}
      </Box>
      <form onSubmit={handleLocalLogin}>
        <TextField size='small' sx={{m:'10px 0', width:'100%'}} disabled={Boolean(podId)} value={scope} onChange={(e) => { setScope(e.target.value) }} label={t('Pod Id')} type="text" name="podId" />
        <TextField required autoComplete='username' size='small' sx={{m:'10px 0', width:'100%'}} value={login} onChange={(e) => { setLogin(e.target.value) }} label={t('Login')} type="text" name="login" />
        <TextField required autoComplete='current-password' size='small' sx={{m:'10px 0 20px 0', width:'100%'}} value={password} onChange={(e) => { setPassword(e.target.value) }} label={t('Password')} type="password" name="password" />
        <Box style={{textAlign:'right'}}>
          <Button onClick={()=> { setShowShrimpLogin(false); setScope(''); setLogin(''); setPassword(''); navigate('/') }}>{t('Cancel')}</Button>
          <Button type="submit" variant='contained'>{t('OK')}</Button>
        </Box>
      </form>
    </Box>

    return (
      <>
        <div style={{justifySelf: "end", margin: "10px"}}>
          <LanguageSwitch />
        </div>
        <Infobox sx={{maxHeight:'100%'}}>
          <Box style={{minHeight:100, color:'#564c44', marginBottom:20}}>
            <img src="/img/shrimpy-solo.png" style={{width:100, float:'left', marginRight:10}} alt="SHRIMP Logo" />
            <Box sx={{paddingBottom:1}}>
              <span style={{fontSize:'120%'}}>{t('Welcome to SHRIMP!')}</span>
              <Typography variant="body2" color="text.secondary">{t("SHRIMP is currently available at select institutions. If you would like to try SHRIMP for free, please get in touch with us at testen@shrimpp.de")}</Typography>
            </Box>
          </Box>
          <Box sx={{display: "grid", gridTemplateRows: "min-content auto min-content", alignItems: "start", paddingLeft:1}}>
            <Box>
              { showShrimpLogin ? localForm : <Box sx={{paddingBottom:2}}>{buttons}</Box> }
            </Box>
          </Box>
        </Infobox>
      </>
    )
  }

export default Login